
import axios from 'axios'
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import { queryCheckList, exportCheckList } from '@/api/advance.ts';

import dayjs from 'dayjs'
import store from '@/store/index'
import cashInfo from '@/views/salary/management-salary/components/cash-info.vue'
import dialogCheckDetail from './components/dialog-check-detail.vue';
import dialogUploadSalary from './components/dialog-upload-salary.vue';

const curMonth = dayjs().format('YYYY-MM')
// const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')
export default defineComponent({
    name: 'Bill',
    components: {
        searchComponent,
        tableComponent,
        cashInfo,
        dialogCheckDetail,
        dialogUploadSalary
    },
    data() {
        return {
            searchList: {
                queryMonth: {
                    name: '核算月份',
                    prop: 'queryMonth',
                    type: 'date',
                    dateType: 'month',
                    placeholder: '选择时间',
                    value: curMonth,
                    defaultValue: curMonth,
                    unClearable: true,
                    valueFormat: 'YYYY-MM'
                },
                queryInfoText: {
                    name: '员工信息',
                    prop: 'queryInfoText',
                    type: 'input',
                    placeholder: '请输入员工名称',
                    value: '',
                    defaultValue: ''
                },
                isCheck: {
                    name: '核算状态',
                    prop: 'isCheck',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已核算',
                            value: 1
                        },
                        {
                            label: '未核算',
                            value: 0
                        },
                    ],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                }
            } as any,
            buttonList: [
                // {
                //     name: '导出表格',
                //     icon: 'export',
                //     type: '',
                //     disabled: false,
                //     fn: () => {
                //         this.exporttable()
                //     }
                // }
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'userName',
                    label: '人员名称'
                },
                {
                    prop: 'idCardNo',
                    label: '身份证号',
                    width: 170
                },
                {
                    prop: 'phone',
                    label: '手机号',
                    width: 120
                },
                {
                    prop: 'salaryAmount',
                    label: '薪资标准',
                    prefix: '¥',
                    defaultValue: '--'
                },
                {
                    prop: 'actualAmount',
                    label: '发薪金额',
                    prefix: '￥',
                    color: '#409eff',
                    defaultValue: '0元',
                    handleClick: (data: any) => {
                        ; (this as any).type = "pay"
                            ; (this as any).queryData = data
                            ; (this as any).queryData.queryDate = (this as any).searchList.queryMonth.value
                            ; (this as any).showDialog = true
                    }
                },
                {
                    prop: 'actualAdvance',
                    label: '实际预支',
                    prefix: '￥',
                    color: '#409eff',
                    defaultValue: '0元',
                    handleClick: (data: any) => {
                        ; (this as any).type = "advance"
                            ; (this as any).queryData = data
                            ; (this as any).queryData.queryDate = (this as any).searchList.queryMonth.value
                            ; (this as any).showDialog = true
                    }
                },
                {
                    prop: 'shouldPayAmount',
                    label: '应发薪资',
                    prefix: '￥',
                },
                {
                    prop: 'isCheck',
                    label: '核算状态',
                    map: {
                        0: '未核算',
                        1: '已核算'
                    },
                    colorMap: {
                        0: '#5E617D',
                        1: '#00D679'
                    }
                },
                {
                    prop: 'coverTime',
                    label: '覆盖时间',
                    width: 190
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 80,
                    fixed: 'right'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            approvalId: '',
            isApprovalPopup: false,
            selects: [],
            userId: 0,
            accountExpireInfoStatus: 0,
            isBatchPay: false,
            activeName: 'pay',
            showDialog: false,
            type: 'pay',
            showSalaryDialog: false,
            queryData: {}
        }
    },
    computed: {
        queryDate() {
            const start = dayjs((this as any).searchList.queryMonth.value).startOf('month').format('YYYY-MM-DD')
            const end = dayjs((this as any).searchList.queryMonth.value).endOf('month').format('YYYY-MM-DD')
            return [start, end]
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        exporttable() {
            const query = this.createQuery(false)
            query.ids = this.selects.join(',')
            exportCheckList(query).then(res => {
                window.location.href = process.env.VUE_APP_BASE_URL + '/common/download?delete=true&fileName=' + res
                ElMessage.success('导出成功！')
            })
        },
        getData() {
            const query = this.createQuery()
            queryCheckList(query).then((res: any) => {
                console.log(1111, res);
                this.tableData = res.list
                this.pageInfo.total = res.total
            })
        },
        handleSelectionChange(row: any) {
            this.selects = row.map((item: any) => item.idCardNo)
        },
        createQuery(bl=true) {
            const query = {} as any
            if(bl) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`start${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        handleArchive(row: any) {
            this.$router.push({
                path: '/bill/accountCheckDetail',
                query: {
                    id: btoa(row.idCardNo)
                }
            })
        },
        handleRefresh() {
            console.log(11111111);
            this.pageInfo.pageNum = 1
            this.getData()
        }
    }
})
