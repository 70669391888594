
import { defineComponent } from 'vue'

export default defineComponent({
    props: {

    },
    components: {
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
})

