
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        errorList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    components: {
    },
    data() {
        return {
            tableData: []
        }
    },
    computed: {

    },
    watch: {
        errorList(newval) {
            this.tableData = newval
        }
    },
    methods: {
        handleEdit(row: any) {
            row.editable = !row.editable
        },
        handlePreview() {

        },
        handleRemove() {

        },
        beforeRemove() {

        },
        handleExceed() {

        }
    }
})

