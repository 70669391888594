
import { defineComponent } from 'vue'
import global from '@/utils/global'
import { ElMessage } from 'element-plus'
console.log(212121, global.token);
export default defineComponent({
    props: {

    },
    components: {
    },
    data() {
        return {
            dateValue: '',
            fileList: [],
            token: global.token,
            limit: 10
        }
    },
    computed: {
        uploadAction() {
            return process.env.VUE_APP_BASE_URL + '/rest/pay/company/employee/salary/import-employee-salary'
        }
    },
    watch: {

    },
    methods: {
        handleBeforeUpload(file: any) {
            console.log(111, file);
            if (file.name.toLowerCase().endsWith('.xls') || file.name.toLowerCase().endsWith('.xlsx')) {
                if (file.size <= this.limit * 1024 * 1024) {
                    return true
                } else {
                    ElMessage.error(`请上传小于${this.limit}M大小的文件`)
                    return false
                }
            } else {
                ElMessage.error('请上传格式为xls或xlsx的文件')
                return false
            }

        },
        handlePreview() {

        },
        handleRemove() {

        },
        beforeRemove() {

        },
        handleExceed() {

        },
        onUpFile(ev: any) {
            console.log(ev);
        },
        fileSuccess(res: any, file: any) {
            console.log(res, file);
            if (res.code === 500 && res.data?.length) {
                this.$emit('uploadData', {
                    step: 2,
                    data: res.data
                })
            } else if(res.code === 200) {
                this.$emit('uploadData', {
                    step: 3,
                })
            }
        },
        fileEerror() {
            ElMessage.error('文件上传失败')
        },
    }
})

