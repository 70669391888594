
import { defineComponent } from 'vue'
import tableComponent from '@/components/table-component/index.vue'
import step1 from './upload-salary-step1.vue';
import step2 from './upload-salary-step2.vue';
import step3 from './upload-salary-step3.vue';
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        // type: {
        //     type: String,
        //     default: 'pay'
        // }
    },
    components: {
        tableComponent,
        step1,
        step2,
        step3
    },
    emits: ['update:show', 'refresh'],
    data() {
        return {
            dialogVisible: false,
            activeStep: 1,
            dateValue: '',
            errorList: []
        }
    },
    computed: {

    },
    watch: {
        show(newval) {
            this.dialogVisible = newval
            if (!newval) {
                this.activeStep = 1
                ;(this as any).$refs.step1.dateValue = ''
                this.$emit('refresh')
            }
        },
        dialogVisible(newval) {
            this.$emit('update:show', newval)
        },
    },
    methods: {
        handleClose(done: Function) {
            this.dialogVisible = false
            done()
        },
        handleStep(data: any) {
            if (data) {
                this.activeStep = data.step
                this.errorList = data.data
            }
        }
    }
})

